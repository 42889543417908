import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  AutoComplete,
  AutoCompleteInputStyle,
  AutoCompleteSingleSelectConfig,
  SearchIcon,
} from '@hcs/design-system';
import { AddressSearchHit } from '@hcs/types';

import { useAddressSearch } from '../../hooks/useAddressSearch';
import { NoOptionsContent } from '../NoOptionsContent/NoOptionsContent';

import styles from './AddressSearch.module.css';

export interface AddressSearchTheme {
  AutoComplete?: {
    Input?: string;
  };
}

export interface AddressSearchProps {
  // Minimum number of characters before calling api
  minChars?: number;
  focusOnMount?: boolean;
  placeholder?: string;
  prefilledSuggestions?: string;
  prefilledSuggestionsLabel?: string;
  className?: string;
  disableClear?: boolean;
  hideChevron?: boolean;
  inputStyle?: AutoCompleteInputStyle;
  onChange?: (searchStr: string) => void;
  onSelect: (addressSearchHit: AddressSearchHit) => void;
  onClearSelection?: VoidFunction;
  hasAvm?: boolean;
  selectEngagement?: {
    dataHcEventSection?: string;
    dataHcEventType?: string;
    dataHcEventName?: string;
  };
  showSearchIcon?: boolean; // should only be used with inputStyle: 'form'
  clearAfterSelect?: boolean;
  theme?: AddressSearchTheme;
  fallbackValue?: string;
  controlled?: {
    value: string;
    setValue: (val: string) => void;
  };
}

const dataHcName = 'address-search';
export const AddressSearch = ({
  className,
  placeholder,
  onChange,
  onSelect,
  onClearSelection,
  hasAvm,
  focusOnMount,
  disableClear,
  hideChevron,
  inputStyle = 'search',
  minChars = 3,
  showSearchIcon,
  selectEngagement,
  clearAfterSelect,
  theme,
  fallbackValue,
  controlled,
}: AddressSearchProps) => {
  const [buildingId, setBuildingId] = useState<string | undefined>();
  const [searchStr, setSearchStr] = useState('');
  const { data, mutate: addressSearchSubmit, isLoading } = useAddressSearch();
  const inputRef = useRef<HTMLInputElement>(null);
  const currentSearchValue = controlled ? controlled.value : searchStr;
  useEffect(() => {
    if (focusOnMount) {
      inputRef.current?.focus();
    }
    // we only want to run this effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = useMemo(
    () =>
      data?.hits.map((addressSearchHit, i) => {
        const isMultiUnit =
          data.type === 'building' && addressSearchHit.fields.isUnit === '1';
        return {
          label: isMultiUnit ? (
            <div
              className={styles.MultiUnitHit}
              data-hc-name={`mulit-unit-hit-${addressSearchHit.id}-${i}`}
            >
              <div>{addressSearchHit.fields.fullLine}</div>
              <div>Multi Unit</div>
            </div>
          ) : (
            addressSearchHit.fields.fullLine
          ),
          ...(isMultiUnit ? {} : selectEngagement),
          searchString: addressSearchHit.fields.fullLine,
          value: addressSearchHit.id,
        };
      }) || [],
    // assuming selectEngagement will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleChange = (newSearchStr: string | null) => {
    if (newSearchStr?.length && newSearchStr.length >= minChars) {
      onChange?.(newSearchStr);
      if (newSearchStr) {
        addressSearchSubmit({
          searchStr: newSearchStr,
          bldgId: buildingId,
          hasAvm,
        });
      }
      if (buildingId && newSearchStr.slice(0, searchStr.length) !== searchStr) {
        setBuildingId(undefined);
      }

      if (controlled) {
        controlled.setValue(newSearchStr);
      } else {
        setSearchStr(newSearchStr);
      }
    }
  };

  const handleSelect: AutoCompleteSingleSelectConfig<string>['onSelect'] = (
    id: string | null,
    manualControls
  ) => {
    if (id) {
      const selectedHit = data?.hits.find((d) => d.id === id);
      const selectedOption = options?.find((o) => o.value === id) || null;
      if (selectedHit) {
        if (selectedHit.fields.isUnit === '1' && data?.type === 'building') {
          const newSearchStr = `${
            selectedHit.fields.partialLine || currentSearchValue
          } Apt`;
          manualControls?.setSearch(newSearchStr + ' ');
          setBuildingId(selectedHit.fields.bldgId);
          addressSearchSubmit({
            searchStr: newSearchStr,
            hasAvm,
            bldgId: selectedHit.fields.bldgId,
          });
          inputRef.current?.focus();
        } else {
          onSelect(selectedHit);
          manualControls?.setIsActive(false);
          manualControls?.setSelectedOption(selectedOption);
        }
      }
    }

    if (id === null) {
      onClearSelection?.();
    }

    if (clearAfterSelect) {
      if (controlled) {
        controlled.setValue('');
      } else {
        setSearchStr('');
      }
    }
  };

  const isAddressSearchLoading = isLoading && data === undefined;

  return (
    <AutoComplete
      ref={inputRef}
      dataHcName={dataHcName}
      className={className}
      placeholder={placeholder}
      onChange={handleChange}
      options={currentSearchValue.length >= minChars ? options : []}
      optionMode="async"
      disableClear={disableClear}
      hideChevron={hideChevron}
      inputStyle={inputStyle}
      config={{
        selectType: 'single',
        value: currentSearchValue,
        onSelect: handleSelect,
        manualControl: true,
      }}
      inputIcon={
        showSearchIcon ? (
          <SearchIcon size="sm" dataHcName={`${dataHcName}-search-icon`} />
        ) : undefined
      }
      clearAfterSelect={clearAfterSelect}
      theme={theme?.AutoComplete}
      fallbackValue={fallbackValue}
      isLoading={isAddressSearchLoading}
      optionsContent={
        currentSearchValue.length < minChars ? (
          `Please enter at least ${minChars} characters to search.`
        ) : options.length || isAddressSearchLoading ? undefined : (
          <NoOptionsContent
            currentSearch={currentSearchValue}
            buildingId={buildingId}
          />
        )
      }
    />
  );
};
