import { useMemo } from 'react';
import { jwtDecode } from 'jwt-decode';

import { useAccount } from './useAccount';

export const useIsImpersonatingUser = () => {
  const accountQuery = useAccount();
  const { isInitialLoading, data: account } = accountQuery;

  const isImpersonatingUser = useMemo(() => {
    if (isInitialLoading) {
      return undefined;
    }
    const decoded = account?.validity.token
      ? jwtDecode<{
          email: string;
          imp_id?: number;
          exp: number;
          iat: number;
          id: number;
          organization_id: number;
        }>(account.validity.token)
      : undefined;
    return !!decoded?.imp_id && decoded.imp_id !== account?.user.id;
  }, [isInitialLoading, account?.validity.token, account?.user.id]);
  return {
    ...accountQuery,
    data: isImpersonatingUser,
  };
};
