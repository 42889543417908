import { useEffect, useMemo } from 'react';

import { GlobalEngagementEventFields } from '@hcs/types';

import { useGlobalEngagementEventSlice } from './useGlobalEngagementEventSlice';
import { useRegisterAndPeristEngagementTrackingData } from './useRegisterAndPeristEngagementTrackingData';

export const useUtmCodeEngagementEventData = () => {
  const {
    actions: { registerGlobalEventData },
  } = useGlobalEngagementEventSlice();
  const searchParams = new URLSearchParams(window.location.search);
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_content = searchParams.get('utm_content');
  const utm_medium = searchParams.get('utm_medium');
  const utm_source = searchParams.get('utm_source');
  const referrer = searchParams.get('referrer');
  const utm_adgroup = searchParams.get('utm_adgroup');
  const utm_region = searchParams.get('utm_region');
  const utm_term = searchParams.get('utm_term');
  const utm = useMemo(() => {
    return {
      utm: {
        utm_campaign,
        utm_content,
        utm_medium,
        utm_source,
        referrer,
        utm_adgroup,
        utm_region,
        utm_term,
      },
    };
  }, [
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    referrer,
    utm_adgroup,
    utm_region,
    utm_term,
  ]);
  const { registerAndPersistEventData } =
    useRegisterAndPeristEngagementTrackingData();
  useEffect(() => {
    registerAndPersistEventData('utm', utm);
  }, [utm, registerAndPersistEventData]);
  useEffect(() => {
    const utmCodes: Partial<GlobalEngagementEventFields> = {};
    if (utm_campaign) {
      utmCodes.utmCampaign = utm_campaign;
    }
    if (utm_content) {
      utmCodes.utmContent = utm_content;
    }
    if (utm_term) {
      utmCodes.utmTerm = utm_term;
    }
    if (utm_medium) {
      utmCodes.utmMedium = utm_medium;
    }
    if (utm_source) {
      utmCodes.utmSource = utm_source;
    }
    registerGlobalEventData({
      ...utmCodes,
    });
  }, [
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    referrer,
    utm_adgroup,
    utm_region,
    utm_term,
    registerGlobalEventData,
  ]);
};
