import { AxiosClient } from '@hcs/http-clients';
import {
  AccountTokenResponse,
  SamlapiDestinationResponseExt,
  SamlapiLoginLinkRespExt,
} from '@hcs/types';
import { ACCOUNT_URL_EXTERNAL } from '@hcs/urls';

export const AccountApi = {
  sendSsoLoginLinkEmail: async ({
    email,
    url,
  }: {
    email: string;
    url?: string;
  }) => {
    const response = await AxiosClient.post<SamlapiLoginLinkRespExt>(
      `${ACCOUNT_URL_EXTERNAL}/saml/login-link?url=${url}`,
      {
        email,
      }
    );
    return response.data;
  },
  fetchOrgLoginDestination: async ({
    orgSlug,
    destinationUrl,
  }: {
    orgSlug: string;
    destinationUrl?: string;
  }) => {
    const response = await AxiosClient.get<SamlapiDestinationResponseExt>(
      `${ACCOUNT_URL_EXTERNAL}/saml/${orgSlug}/destination?url=${destinationUrl}`
    );
    return response.data;
  },
  issueSingleUseToken: async () => {
    const response = await AxiosClient.post<AccountTokenResponse>(
      `${ACCOUNT_URL_EXTERNAL}/auth/issue-single-use-token`,
      undefined,
      {
        withCredentials: true,
      }
    );
    return response.data;
  },
};
