import { useNavigate } from 'react-router';

import { APP_CONFIG_USER_ADMIN, VIEW_PATHS_USER_ADMIN } from '../constants';

export const useNavigateToApiKeys = (): VoidFunction => {
  const navigate = useNavigate();
  return () => {
    navigate(
      `${APP_CONFIG_USER_ADMIN.rootPath}/${VIEW_PATHS_USER_ADMIN.API_KEYS}`
    );
  };
};
