import { useAccount } from '@hcs/auth';
import { CapabilitiesCheckParams } from '@hcs/types';

import { useCapabilitiesCheckForUser } from './useCapabilitiesCheckForUser';

export const useCapabilitiesCheck = (
  capabilitiesCheckParams: CapabilitiesCheckParams
) => {
  const legacyAccountQuery = useAccount();
  return useCapabilitiesCheckForUser(
    legacyAccountQuery.data?.user.id,
    capabilitiesCheckParams
  );
};
