import { useMemo } from 'react';

import { useExternalAppConfigsByKey } from '@hcs/hc-products';
import { AppConfig } from '@hcs/types';
import { logWarning } from '@hcs/utils';

import { useCapabilitiesForOrg } from './useCapabilitiesForOrg';

export const useAppConfigsForOrg = (orgId: number | undefined) => {
  const capsQuery = useCapabilitiesForOrg(orgId);
  const { data: capabilities } = capsQuery;
  const appConfigsMap = useExternalAppConfigsByKey();
  const data = useMemo(() => {
    if (!capabilities) {
      return [];
    }

    const productAccessCapabilities = capabilities.filter(
      (capability) => capability.category === 'product-access'
    );
    const appConfigsArray = Object.values(appConfigsMap);
    return productAccessCapabilities.reduce<AppConfig[]>(
      (accum, capability) => {
        const appConfig = appConfigsArray.find(
          (appConfigItem) =>
            appConfigItem.applicationKey === capability.application
        );
        if (appConfig) {
          accum.push(appConfig);
        } else {
          logWarning(
            `useAppConfigsForOrg: missing app config for capability application: ${capability.application}`
          );
        }
        return accum;
      },
      []
    );
  }, [capabilities, appConfigsMap]);
  return {
    ...capsQuery,
    data: capsQuery.isSuccess ? data : undefined,
  };
};
