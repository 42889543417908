import { useMemo } from 'react';

import { useFeatureFlagsForUser } from '@hcs/huell';
import { CapabilitiesCheckParams } from '@hcs/types';
import { combineUseQueryResult } from '@hcs/utils';

import { checkHasCapabilities } from '../utils/capabilityCheck.utils';

import { useCapabilitiesForUser } from './useCapabilitiesForUser';

export const useCapabilitiesCheckForUser = (
  userId: number | undefined,
  { capabilityKeys, operation }: CapabilitiesCheckParams
) => {
  const capabilitiesQuery = useCapabilitiesForUser(userId);
  const { data: capabilitiesData } = capabilitiesQuery;
  const ffQuery = useFeatureFlagsForUser();
  const { data: featureFlags } = ffQuery;
  const combinedQuery = combineUseQueryResult([capabilitiesQuery, ffQuery]);
  const capbilitiyChecks = useMemo(() => {
    if (!capabilitiesData) {
      return undefined;
    }
    return checkHasCapabilities(
      { capabilityKeys, operation },
      capabilitiesData,
      featureFlags
    );
  }, [capabilitiesData, capabilityKeys, operation, featureFlags]);
  return {
    ...combinedQuery,
    data: capbilitiyChecks,
  };
};
