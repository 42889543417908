import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, DialogAction } from '@hcs/design-system';
import { BuyBox, BuyBoxFormData, MeaningfulEventTypes } from '@hcs/types';

import { BuyBoxSaveUnexpectedError } from '../../components/BuyBoxSaveUnexpectedError';
import { useBuyBox } from '../../hooks/useBuyBox';
import { useBuyBoxFormChange } from '../../hooks/useBuyBoxFormChange';
import { useBuyBoxPatch } from '../../hooks/useBuyBoxPatch';
import {
  buyBoxToFormDefaultValues,
  buyBoxValidationSchema,
  DEFAULT_FORM_VALUES,
  editFormToPatchBuyBox,
} from '../../utils';
import { BuyBoxMatchCount } from '../BuyBoxMatchCount';

import { EditBuyBoxForm } from './EditBuyBoxForm';

import styles from './EditBuyBoxDialog.module.css';

interface EditBuyBoxDialogProps {
  active: boolean;
  buyBoxId?: BuyBox['id'];
  isFiltersVersion?: boolean;
  /** called when the form's dirty state changes */
  onFormDirty?: (isDirty: boolean) => void;
  /** pass formdata and whether form is dirty on change */
  onFormChange?: (formData: BuyBoxFormData) => void;
  /** called after a successful save */
  onSaveSuccess?: () => void;
  /** called when the form's validity changes */
  onFormValidityChange?: (isValid: boolean) => void;
  onClose: (savedId?: number) => void;
}
const dataHcName = 'edit-buy-box-dialog';
export const EditBuyBoxDialog = (props: EditBuyBoxDialogProps) => {
  const {
    active,
    buyBoxId,
    isFiltersVersion = false,
    onFormDirty,
    onFormChange,
    onSaveSuccess,
    onFormValidityChange,
    onClose,
  } = props;
  const { data: buyBox } = useBuyBox(buyBoxId || null);
  const {
    mutate: saveEdits,
    isLoading: isLoadingSave,
    isSuccess,
    reset: resetMutationState,
    isError,
  } = useBuyBoxPatch();
  const [errorCleared, setErrorCleared] = useState(false);

  const methods = useForm<BuyBoxFormData>({
    mode: 'onBlur',
    resolver: yupResolver(buyBoxValidationSchema),
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const { control, reset, formState, handleSubmit } = methods;
  // destructuring of formState suggested by docs https://react-hook-form.com/api/useform/formstate
  const { isValid, isDirty } = formState;

  useBuyBoxFormChange(control, onFormChange);

  const handleClose = useCallback(
    (savedId?: number) => {
      if (!isFiltersVersion) {
        // reset form with empty values object
        reset({});
      }
      onClose(savedId);
    },
    [reset, onClose, isFiltersVersion]
  );

  // update default values after getCollectionQuery finishes
  useEffect(() => {
    if (buyBox) {
      reset(buyBoxToFormDefaultValues(buyBox));
    }
  }, [buyBox, reset]);

  useEffect(() => {
    if (onFormDirty) {
      onFormDirty(isDirty);
    }
  }, [isDirty, onFormDirty]);

  useEffect(() => {
    if (onFormValidityChange) {
      onFormValidityChange(isValid);
    }
  }, [isValid, onFormValidityChange]);

  useEffect(() => {
    if (isSuccess) {
      if (onSaveSuccess) {
        onSaveSuccess();
      }
      handleClose(buyBoxId);
      resetMutationState();
    }
  }, [isSuccess, handleClose, buyBoxId, onSaveSuccess, resetMutationState]);

  useEffect(() => {
    if (isError) {
      setErrorCleared(false);
    }
  }, [isError]);

  const onSubmit: SubmitHandler<BuyBoxFormData> = (formData) => {
    if (buyBox) {
      saveEdits(editFormToPatchBuyBox(buyBox.id, formData));
    }
  };

  // show error dialog if there is a back-end save error AND it's not cleared on front-end
  const showErrorDialog = isError && !errorCleared;

  if (!buyBoxId) {
    return null;
  }

  const defaultActions: DialogAction[] = [];
  if (isFiltersVersion) {
    defaultActions.push({
      label: 'Show Results',
      onClick: () => handleClose(),
      dataHcName: `${dataHcName}-show-results-button`,
      disabled: !isValid || !buyBox,
      secondary: true,
    });
  }
  defaultActions.push({
    label: isFiltersVersion ? 'Update Buy Box' : 'Save',
    onClick: () => handleSubmit(onSubmit)(),
    dataHcName: isFiltersVersion
      ? `${dataHcName}-update-buybox-button`
      : `${dataHcName}-save-button`,
    dataHcEventType: MeaningfulEventTypes.Goal,
    dataHcEventName: 'Edit Buy Box',
    disabled: isLoadingSave || !isValid || !buyBox,
  });
  const errorActions = [
    {
      label: 'Back',
      onClick: () => setErrorCleared(true),
      dataHcName: `${dataHcName}-back-button`,
      disabled: false,
    },
  ];

  const actions: DialogAction[] = !showErrorDialog
    ? defaultActions
    : errorActions;
  const content = !showErrorDialog ? (
    <div data-hc-name={dataHcName} className={styles.FormContainer}>
      <FormProvider {...methods}>
        <EditBuyBoxForm msaId={buyBox?.msaId} buyBoxId={buyBoxId} />
      </FormProvider>
    </div>
  ) : (
    <BuyBoxSaveUnexpectedError
      dataHcName={`${dataHcName}-save-unexpected-error`}
      className={styles.ErrorMsg}
    />
  );

  return (
    <Dialog
      dataHcName={dataHcName}
      type="small"
      active={active}
      title={
        !showErrorDialog
          ? isFiltersVersion
            ? null
            : 'Edit Existing Buy Box'
          : "We're sorry, there's been an error."
      }
      subtitle={
        !showErrorDialog && !isFiltersVersion ? (
          <BuyBoxMatchCount control={methods.control} />
        ) : null
      }
      onClose={handleClose}
      actions={actions}
      preventClickOutsideClose
      maxHeight={'calc(100vh - 160px)'}
    >
      {content}
    </Dialog>
  );
};
