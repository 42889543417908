import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import FileDownload from 'js-file-download';
import snakecaseKeys from 'snakecase-keys';

import { AxiosClient } from '@hcs/http-clients';
import { AxiosAccessTokenClientEhrm } from '@hcs/http-clients';
import { User } from '@hcs/types';
import {
  BulkInvitationResponse,
  CheckInvitationCodeResponse,
  CreateInvitationPayload,
  EditUserOrgPermissionsPayload,
  EditUserOrgPermissionsPayloadRequest,
  EditUserRolePayload,
  OrgInvitation,
  OrgUser,
  OrgUserSort,
  OrgUsersResponse,
  ResendInvitationResponse,
  UserApplicationUpdateExternal,
} from '@hcs/types';
import { EHRMANTRAUT_ADMIN_URL, EHRMANTRAUT_URL } from '@hcs/urls';
import { camelCaseToSnakeCase } from '@hcs/utils';

export interface FetchOrgUsersParams {
  page?: number;
  itemsPerPage?: number;
  search?: string;
  orderBy?: {
    field: OrgUserSort['field'];
    direction: OrgUserSort['order'];
  };
}

const fetchOrgUsers = async (params?: FetchOrgUsersParams) => {
  /*
   * Some janky conversion here to give the BE the strange (for querystrings) format it expects for order_by
   * i.e. The BE expects the key for order_by to not be an array key i.e. order_by[]= (as axios.getUri would normally make it since the value is an array)
   * the BE also expects the value to be an array
   */
  const modifiedParams = (params: FetchOrgUsersParams) => {
    return {
      ...params,
      orderBy: params.orderBy
        ? JSON.stringify([
            snakecaseKeys({
              ...params.orderBy,
              field: camelCaseToSnakeCase(params.orderBy.field),
            }),
          ])
        : undefined,
    };
  };

  const response = await AxiosAccessTokenClientEhrm.get<OrgUsersResponse>(
    axios.getUri({
      url: `${EHRMANTRAUT_URL}/org_users`,
      params: params ? snakecaseKeys(modifiedParams(params)) : undefined,
    })
  );
  return camelcaseKeys(response.data, { deep: true });
};

const downloadUsers = async (fileName: string) => {
  const response = await AxiosAccessTokenClientEhrm({
    url: `${EHRMANTRAUT_URL}/org_users/download`,
    responseType: 'blob',
  });
  const file = response.data as Blob;
  FileDownload(file, fileName);
};

const fetchUserData = async (userId: string) => {
  const response = await AxiosAccessTokenClientEhrm.get<User>(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`
  );
  return camelcaseKeys(response.data, { deep: true });
};

const editUserPermissionsForOrg = async (
  userId: number,
  editUserRolePayload: EditUserOrgPermissionsPayload
) => {
  const editUserPermissionsPayload: EditUserOrgPermissionsPayloadRequest = {
    ...editUserRolePayload,
    id: userId,
  };

  const response = await AxiosAccessTokenClientEhrm.put<User>(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`,
    snakecaseKeys(editUserPermissionsPayload)
  );

  return camelcaseKeys(response.data, { deep: true });
};

const editUserRole = async (
  userId: OrgUser['id'],
  editUserRolePayload: EditUserRolePayload
) => {
  const response = await AxiosAccessTokenClientEhrm.put<User>(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`,
    snakecaseKeys(editUserRolePayload)
  );
  return camelcaseKeys(response.data, { deep: true });
};
interface EditUserPermissionsPayload {
  id: OrgUser['id'];
  applications: UserApplicationUpdateExternal[];
}
const editUserPermissions = async (
  userId: OrgUser['id'],
  applicationUpdates: UserApplicationUpdateExternal[]
) => {
  const editUserPermissionsPayload: EditUserPermissionsPayload = {
    id: userId,
    applications: applicationUpdates,
  };

  const response = await AxiosAccessTokenClientEhrm.put<User>(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`,
    snakecaseKeys(editUserPermissionsPayload)
  );

  return camelcaseKeys(response.data, { deep: true });
};

const deleteUser = async (userId: OrgUser['id']) => {
  await AxiosAccessTokenClientEhrm.delete(
    `${EHRMANTRAUT_ADMIN_URL}/user/${userId}`
  );
};

const createInvitation = async (payload: CreateInvitationPayload) => {
  // not bothering with the response but it responds with something close to but not quite an OrgInvitation
  await AxiosAccessTokenClientEhrm.post(
    `${EHRMANTRAUT_URL}/invitation`,
    snakecaseKeys(payload, { deep: true })
  );
};

interface ResendInvitationPayload {
  emailType: 'welcome';
  invitationId: number;
}
const resendInvitation = async (invitationId: OrgInvitation['id']) => {
  const resendInvitationPayload: ResendInvitationPayload = {
    emailType: 'welcome',
    invitationId,
  };
  const response =
    await AxiosAccessTokenClientEhrm.post<ResendInvitationResponse>(
      `${EHRMANTRAUT_URL}/invitation/send-email`,
      // NOTE: for some reason this request should have camelCase keys
      resendInvitationPayload
    );
  return camelcaseKeys(response.data, { deep: true });
};

const deleteInvitation = async (invitationId: OrgInvitation['id']) => {
  await AxiosAccessTokenClientEhrm.delete(
    `${EHRMANTRAUT_ADMIN_URL}/invitation/${invitationId}`
  );
};

const createBulkInvitation = async (payload: FormData) => {
  const response =
    await AxiosAccessTokenClientEhrm.post<BulkInvitationResponse>(
      `${EHRMANTRAUT_URL}/invitation/bulk`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  return camelcaseKeys(response.data, { deep: true });
};

const checkInvitationCode = async (invitationCode: string) => {
  const response = await AxiosClient.get<CheckInvitationCodeResponse>(
    `${EHRMANTRAUT_URL}/invitation/code/${invitationCode}`
  );
  return camelcaseKeys(response.data, { deep: true });
};

export const OrgAdminApi = {
  fetchOrgUsers,
  fetchUserData,
  downloadUsers,
  editUserPermissionsForOrg,
  editUserRole,
  editUserPermissions,
  deleteUser,
  createInvitation,
  resendInvitation,
  deleteInvitation,
  createBulkInvitation,
  checkInvitationCode,
  endLegacySubscriptions: async ({
    orgId,
    date,
  }: {
    orgId: number;
    date: string | null;
  }) => {
    const payload: { organization_id: number; date?: string } = {
      organization_id: orgId,
    };
    if (date) {
      payload.date = date;
    }
    await AxiosAccessTokenClientEhrm.post(
      `${EHRMANTRAUT_URL}/subscription/terminate`,
      payload
    );
  },
};
