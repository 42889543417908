import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { UserAlert } from '@hcs/design-system';
import { CardViewIcon, CompactViewIcon } from '@hcs/design-system';
import { BuyBoxFormData, BuyBoxListItem, MapPageViewMode } from '@hcs/types';

import { BuyBoxAutoComplete } from '../../';
import { EditBuyBoxDialog } from '../../';
import { MAP_PAGE_SEARCH_PARAMS } from '../../constants';

import styles from './TopBar.module.css';

interface TopBarProps {
  className?: string;
  setEditedBB: (bb: BuyBoxFormData) => void;
  setViewMode: (viewMode: MapPageViewMode) => void;
  viewMode: MapPageViewMode;
  dataHcName: string;
}

const SUCCESS_ALERT_TIMEOUT = 5000;

export const TopBar = ({
  className,
  setEditedBB,
  viewMode,
  setViewMode,
  dataHcName,
}: TopBarProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlBuyBoxId = searchParams.get(MAP_PAGE_SEARCH_PARAMS.BuyBoxId);
  const [selectedBB, setSelectedBB] = useState<BuyBoxListItem | null>(null);
  const [filtersDialogActive, setFiltersDialogActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [indicateSaveSuccess, setIndicateSaveSuccess] = useState(false);
  const [indicateBBChange, setIndicateBBChange] = useState(false);
  const toggleViewMode = () => {
    setViewMode(viewMode === 'list' ? 'card' : 'list');
  };

  useEffect(() => {
    const timerVar = indicateBBChange
      ? setTimeout(() => {
          setIndicateBBChange(false);
        }, SUCCESS_ALERT_TIMEOUT)
      : undefined;

    return () => {
      if (timerVar) {
        clearTimeout(timerVar);
      }
    };
  }, [indicateBBChange]);

  useEffect(() => {
    const timerVar = indicateSaveSuccess
      ? setTimeout(() => {
          setIndicateSaveSuccess(false);
        }, SUCCESS_ALERT_TIMEOUT)
      : undefined;

    return () => {
      if (timerVar) {
        clearTimeout(timerVar);
      }
    };
  }, [indicateSaveSuccess]);

  const buyBoxFromSearchParams = searchParams.get(
    MAP_PAGE_SEARCH_PARAMS.BuyBoxId
  );

  useEffect(() => {
    if (selectedBB) {
      if (
        buyBoxFromSearchParams === null ||
        parseInt(buyBoxFromSearchParams) !== selectedBB.id
      ) {
        setSearchParams(
          (searchParams) => {
            searchParams.set(
              MAP_PAGE_SEARCH_PARAMS.BuyBoxId,
              selectedBB.id.toString()
            );
            return searchParams;
          },
          { replace: true }
        );
      }
    }
  }, [selectedBB, buyBoxFromSearchParams, setSearchParams]);

  const handleSaveSuccess = useCallback(() => {
    setIsDirty(false);
    setIndicateSaveSuccess(true);
  }, []);

  const handleSelect = useCallback(
    (buyBox: BuyBoxListItem | null) => {
      setSelectedBB(buyBox);

      // if this isn't the first, initial selection, indicate a buy box change
      if (selectedBB !== null) {
        setIndicateBBChange(true);
      }
    },
    [selectedBB]
  );

  return (
    <div className={classNames(styles.TopBar, className)}>
      <div className={styles.PageTitle} data-hc-name={`${dataHcName}-heading`}>
        Interactive Map
      </div>
      <div className={styles.BuyBoxAutoCompleteContainer}>
        <BuyBoxAutoComplete
          className={styles.BuyBoxAutoComplete}
          config={{
            selectType: 'single',
            onSelect: handleSelect,
            value: selectedBB ? selectedBB.id : null,
            initialSelectedBB: urlBuyBoxId ? parseInt(urlBuyBoxId) : undefined,
          }}
        />
      </div>
      {selectedBB !== null && (
        <Button
          secondary
          dataHcName={`${dataHcName}-filters-button`}
          onClick={() => setFiltersDialogActive(true)}
        >
          Filters
        </Button>
      )}
      {isDirty && isValid && (
        <UserAlert
          className={styles.UserAlert}
          dataHcName={`${dataHcName}-viewing-results-alert`}
          text="Viewing Results Only: Your buy box has not been updated yet"
          type="warning"
        />
      )}
      {isDirty && !isValid && (
        <UserAlert
          className={styles.UserAlert}
          dataHcName={`${dataHcName}-invalid-filters-alert`}
          text="Invalid Filters: Viewing results of last valid filters"
          type="error"
        />
      )}
      {indicateSaveSuccess && (
        <UserAlert
          className={styles.UserAlert}
          dataHcName={`${dataHcName}-edit-success-alert`}
          text="Edit Buy Box Successful"
          type="success"
        />
      )}
      {indicateBBChange && (
        <UserAlert
          className={styles.UserAlert}
          dataHcName={`${dataHcName}-bb-change-alert`}
          text={`Now Viewing ${selectedBB?.name}`}
          type="success"
        />
      )}
      <button
        data-hc-name={`${dataHcName}-toggle-view-mode`}
        className={styles.ViewModeToggle}
        onClick={toggleViewMode}
      >
        {viewMode === 'list' ? (
          <CardViewIcon
            dataHcName={`${dataHcName}-to-cardview-btn`}
            size="lg"
          />
        ) : (
          <CompactViewIcon
            dataHcName={`${dataHcName}-to-listview-btn`}
            size="lg"
          />
        )}
      </button>
      <EditBuyBoxDialog
        active={filtersDialogActive && selectedBB !== null}
        isFiltersVersion
        onSaveSuccess={handleSaveSuccess}
        buyBoxId={selectedBB?.id}
        onFormDirty={setIsDirty}
        onFormValidityChange={setIsValid}
        onFormChange={setEditedBB}
        onClose={() => setFiltersDialogActive(false)}
      />
    </div>
  );
};
