import React from 'react';
import classNames from 'classnames';

import { useCapabilitiesCheck } from '@hcs/authz';
import { Anchor } from '@hcs/design-system';
import { useNavigateToApiKeys } from '@hcs/hc-products';
import { CapabilityKeys } from '@hcs/types';

import styles from './DeveloperCenterBanner.module.css';

const CAPABILITY_KEYS_FOR_DEVELOPER_CENTER_ACCESS: CapabilityKeys[] = [
  'create-api-key',
  'create-test-api-key',
];

interface Props {
  className?: string;
}
const dataHcName = 'developer-center-banner';
export const DeveloperCenterBanner = ({ className }: Props) => {
  const navigateToApiKeys = useNavigateToApiKeys();
  const { data: hasAccessToDeveloperCenter } = useCapabilitiesCheck({
    capabilityKeys: CAPABILITY_KEYS_FOR_DEVELOPER_CENTER_ACCESS,
    operation: 'OR',
  });
  if (!hasAccessToDeveloperCenter) return null;
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.DeveloperCenterBanner, className)}
    >
      <p>
        Looking for API access & documentation? Try our{' '}
        <Anchor dataHcName={`${dataHcName}-link`} onClick={navigateToApiKeys}>
          Developer Center
        </Anchor>
      </p>
    </div>
  );
};
