import { useMutation } from '@tanstack/react-query';

import { useToastSlice } from '@hcs/toast';

import { AccountApi } from '../api/AccountApiExternal.api';

export const useRedirectToSsoProvider = (destinationUrl?: string) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation(
    async ({ orgSlug }: { orgSlug: string }) =>
      await AccountApi.fetchOrgLoginDestination({
        orgSlug,
        destinationUrl,
      }),
    {
      onSuccess: (data) => {
        if (data.url) {
          toastOpen({
            type: 'success',
            title: 'Redirecting to your SSO provider now...',
          });
          // @jnettleman - destination is a safe url from an internal api
          // eslint-disable-next-line xss/no-location-href-assign, scanjs-rules/assign_to_href
          window.location.href = data.url;
        }
      },
      onError: (e, { orgSlug }) => {
        toastOpen({
          type: 'error',
          title: `Failed to find SSO Provider for ${orgSlug}`,
        });
      },
    }
  );
};
