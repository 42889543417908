import React, { useEffect, useState } from 'react';

import { useTrackEngagementEvent } from '@hcs/engagement-tracking';
import { MeaningfulEventTypes } from '@hcs/types';

import { useAddressSearch } from '../../hooks';

import styles from './NoOptionsContent.module.css';

export interface NoOptionsContentProps {
  currentSearch: string;
  buildingId?: string;
}

const dataHcEventSectionNoOptionsContent = 'No Address Search Results Helper';

export const NoOptionsContent = ({
  currentSearch,
  buildingId,
}: NoOptionsContentProps) => {
  const trackEngagementEventMutation = useTrackEngagementEvent();
  const { mutate: addressSearchSubmit } = useAddressSearch({
    onSuccess: (data) => {
      const result = data?.hits;
      if (result && result?.length > 0 && result[0]?.fields?.hasAvm === '0') {
        setNoOptionsContentText('No AVM value available for this address.');
        trackEngagementEventMutation.mutate({
          eventName: 'search-address-no-AVM-value',
          eventData: { eventSection: dataHcEventSectionNoOptionsContent },
          eventType: MeaningfulEventTypes.Goal,
        });
      } else {
        setNoOptionsContentText('Search Address could not be found.');
        trackEngagementEventMutation.mutate({
          eventName: 'search-address-not-found',
          eventData: { eventSection: dataHcEventSectionNoOptionsContent },
          eventType: MeaningfulEventTypes.Goal,
        });
      }
    },
  });
  const [noOptionsContentText, setNoOptionsContentText] = useState<
    string | null
  >(null);

  useEffect(() => {
    // clear no results helper text when search changes
    if (currentSearch || buildingId) setNoOptionsContentText(null);
  }, [currentSearch, buildingId]);

  const handleNoOptionsContentClick = (e: React.MouseEvent<HTMLElement>) => {
    addressSearchSubmit({
      searchStr: currentSearch,
      bldgId: buildingId,
    });
  };

  return noOptionsContentText ? (
    <div>{noOptionsContentText}</div>
  ) : (
    <button
      className={styles.NoOptionsContentBtn}
      onClick={handleNoOptionsContentClick}
      data-hc-event-section={dataHcEventSectionNoOptionsContent}
    >
      Can't find your address?
    </button>
  );
};
