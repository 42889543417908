import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TermsAndPrivacyLinks, useAccount } from '@hcs/auth';
import {
  Anchor,
  AnimatedCheck,
  Button,
  Card,
  EntryPageContainer,
  FormError,
  LayoutBanner,
  LayoutGlobalHeaderChildren,
  LoadingBar,
} from '@hcs/design-system';

import { useResendConfirmationEmail } from '../../hooks';
import { useConfirmUser } from '../../hooks/useConfirmUser';

import styles from './ConfirmUser.module.css';

export const CONFIRM_USER_TOKEN_PARAM = 'token';
const dataHcName = 'confirm-user';
interface ConfirmUserProps {
  header: ReactNode;
  onClickSuccess: VoidFunction;
}
export const ConfirmUser = ({ header, onClickSuccess }: ConfirmUserProps) => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const { data: account } = useAccount();
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const { mutate: sendConfirmationEmail } = useResendConfirmationEmail();
  const { isSuccess: confirmUserSuccess, mutate: confirmUser } = useConfirmUser(
    {
      onError: (e: Error) => {
        setError(e.message);
      },
    }
  );
  const confirmToken = searchParams.get(CONFIRM_USER_TOKEN_PARAM);
  useEffect(() => {
    if (confirmToken) {
      confirmUser({ token: confirmToken });
    }
    // mutation function is not stable, will change reference but not functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmToken]);
  useEffect(() => {
    if (confirmUserSuccess) {
      const interval = setInterval(() => {
        setRedirectCountdown((prev) => {
          if (prev === 0) {
            clearInterval(interval);
            onClickSuccess();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [confirmUserSuccess, onClickSuccess]);

  return (
    <LayoutGlobalHeaderChildren header={header}>
      <EntryPageContainer>
        <LayoutBanner
          title={
            confirmToken
              ? confirmUserSuccess
                ? 'Your e-mail address has been confirmed'
                : 'Confirming...'
              : 'Please Confirm Your Email'
          }
          noOutlet
        >
          <Card
            dataHcName={`${dataHcName}-card`}
            maxWidth={800}
            theme={{ Content: styles.SuccessCardContent }}
          >
            {confirmToken ? (
              confirmUserSuccess ? (
                <>
                  <div className={styles.Success}>
                    <AnimatedCheck /> Your account has been verified and you can
                    now access the Housecanary Platform. Click the button below
                    to access the HouseCanary platform or we will take you there
                    automatically in {redirectCountdown} seconds.{' '}
                  </div>
                  <Button
                    dataHcName={`${dataHcName}-complete`}
                    onClick={onClickSuccess}
                    className={styles.CompleteButton}
                  >
                    Go to Solutions Platform
                  </Button>
                </>
              ) : error ? (
                <FormError dataHcName={`${dataHcName}-error`} value={error} />
              ) : (
                <LoadingBar dataHcName={`${dataHcName}-loading`} />
              )
            ) : (
              <>
                <p>
                  All users must confirm their email address prior to accessing
                  their HouseCanary account. Please check your inbox and click
                  the confirmation link to continue.
                </p>
                <p>
                  If you need to resend the confirmation email, please{' '}
                  <Anchor
                    dataHcName={`${dataHcName}-resend-confirmation`}
                    onClick={
                      account?.user.id
                        ? () => sendConfirmationEmail(account.user.id)
                        : undefined
                    }
                  >
                    click here
                  </Anchor>
                  .
                </p>
              </>
            )}
          </Card>
          <TermsAndPrivacyLinks />
        </LayoutBanner>
      </EntryPageContainer>
    </LayoutGlobalHeaderChildren>
  );
};
