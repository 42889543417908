import React, { useState } from 'react';
import classNames from 'classnames';

import { TopLevelBanner } from '@hcs/design-system';

import { useAccount, useIsImpersonatingUser } from '../../hooks';

import styles from './LoginAsWarning.module.css';

const dataHcName = 'login-as-warning';
export const LoginAsWarning = () => {
  const { data: account } = useAccount();
  const [position, setPosition] = useState<'top' | 'bottom'>('top');
  const { data: isImpersonatingUser } = useIsImpersonatingUser();

  const nextPos = position === 'top' ? 'bottom' : 'top';
  if (isImpersonatingUser) {
    return (
      <TopLevelBanner
        dataHcName={dataHcName}
        className={classNames(styles.LoginAsWarning, {
          [styles.bottom]: position === 'bottom',
        })}
        actionButton={{
          label: `Move to ${nextPos}`,
          onClick: () => setPosition(nextPos),
        }}
      >
        Warning! Logged In As:
        <br />
        <div className={styles.Info}>
          {account?.user.email} / {account?.currentOrganization.slug}
        </div>
      </TopLevelBanner>
    );
  }
  return null;
};
