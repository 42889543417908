import { useQuery } from '@tanstack/react-query';

import {
  CapabilitiesResponse,
  Capability,
  DexpEndpointTierEntitlements,
  DexpEndpointTiers,
} from '@hcs/types';

import { HcProductsApi } from '../api/HcProducts.api';

const QUERY_DEXP_ENDPOINT_TIERS = 'QUERY_DEXP_ENDPOINT_TIERS';
const buildTierDescription = (tier: string, caps: CapabilitiesResponse) => {
  const endpointsByCategory: Record<string, Capability[]> = {};
  caps.capabilities?.forEach((cap) => {
    if (cap.category === 'endpoint') {
      const endpointCategory = cap.key!.split('/')[0];
      if (endpointCategory && endpointsByCategory[endpointCategory]) {
        endpointsByCategory[endpointCategory].push(cap);
      } else if (endpointCategory) {
        endpointsByCategory[endpointCategory] = [cap];
      }
    }
  });
  const categories: { category: string; endpoints: Capability[] }[] = [];
  Object.keys(endpointsByCategory)
    .sort()
    .forEach((category) => {
      const endpoints = endpointsByCategory[category];
      if (endpoints) {
        categories.push({
          category,
          endpoints,
        });
      }
    });
  return {
    ...caps,
    tier,
    categories,
  };
};

export const useDexpEndpointTiers = () => {
  return useQuery([QUERY_DEXP_ENDPOINT_TIERS], async () => {
    const [capsBasic, capsPremium, capsPremiumPlus, capsPropEst, capsBuildTo] =
      await Promise.all([
        HcProductsApi.fetchDexpEntitlementCapabilities({
          entitlementKey: DexpEndpointTierEntitlements.Basic,
        }),
        HcProductsApi.fetchDexpEntitlementCapabilities({
          entitlementKey: DexpEndpointTierEntitlements.Premium,
        }),
        HcProductsApi.fetchDexpEntitlementCapabilities({
          entitlementKey: DexpEndpointTierEntitlements.PremiumPlus,
        }),
        HcProductsApi.fetchDexpEntitlementCapabilities({
          entitlementKey: DexpEndpointTierEntitlements.PropertyEstimate,
        }),
        HcProductsApi.fetchDexpEntitlementCapabilities({
          entitlementKey: DexpEndpointTierEntitlements.BuildToApi,
        }),
      ]);
    const result: DexpEndpointTiers = {
      [DexpEndpointTierEntitlements.Basic]: buildTierDescription(
        'Basic Data Points',
        capsBasic
      ),
      [DexpEndpointTierEntitlements.Premium]: buildTierDescription(
        'Premium Data Points',
        capsPremium
      ),
      [DexpEndpointTierEntitlements.PremiumPlus]: buildTierDescription(
        'Premium-Plus Data Points',
        capsPremiumPlus
      ),
      [DexpEndpointTierEntitlements.PropertyEstimate]: buildTierDescription(
        'Property Estimate API',
        capsPropEst
      ),
      [DexpEndpointTierEntitlements.BuildToApi]: buildTierDescription(
        'Build to Rent API',
        capsBuildTo
      ),
    };
    return result;
  });
};
