import { RequireCertainObjectKeys } from '../TypeScriptHelpers.types';

import {
  OrganizationapiBulkInviteUploadTaskStatusResponse as OrganizationapiBulkInviteUploadTaskStatusResponseExt,
  OrganizationapiCreateOrgInviteRequest as OrganizationapiCreateOrgInviteRequestExt,
  OrganizationapiDeleteInviteReq as OrganizationapiDeleteInviteReqExt,
  OrganizationapiInviteListResponse as OrganizationapiInviteListResponseExt,
  OrganizationapiInviteResponse as OrganizationapiInviteResponseExt,
  OrganizationapiOrgResponse as OrganizationapiOrgResponseExt,
  OrganizationapiOrgUserResponse as OrganizationOrgUserResponseExt,
  OrganizationapiResendOrgInviteRequest as OrganizationapiResendOrgInviteRequestExt,
  OrganizationapiUploadOrgInvitesResponse as OrganizationapiUploadOrgInvitesResponseExt,
} from './generated/AccountApi.types';
import {
  OrganizationapiBulkInviteUploadTaskStatusResponse as OrganizationapiBulkInviteUploadTaskStatusResponseInt,
  OrganizationapiCreateOrgInviteRequest as OrganizationapiCreateOrgInviteRequestInt,
  OrganizationapiCreateOrgRequest,
  OrganizationapiDeleteInviteReq as OrganizationapiDeleteInviteReqInt,
  OrganizationapiInviteListResponse as OrganizationapiInviteListResponseInt,
  OrganizationapiInviteResponse as OrganizationapiInviteResponseInt,
  OrganizationapiOrgResponse as OrganizationapiOrgResponseInt,
  OrganizationapiOrgUserResponse as OrganizationapiOrgUserResponseInt,
  OrganizationapiResendOrgInviteRequest as OrganizationapiResendOrgInviteRequestInt,
  OrganizationapiUpdateOrgRequest as OrganizationapiUpdateOrgRequestInt,
  OrganizationapiUploadOrgInvitesResponse as OrganizationapiUploadOrgInvitesResponseInt,
} from './generated/ManagerApi.types';
import { SelfServeSubscriptionTypes } from './SelfService.types';

export type OranizationSubscriptionType =
  | OrgType
  | 'self-service'
  | SelfServeSubscriptionTypes;

export enum OrgType {
  Enterprise = 'enterprise',
  SelfServe = 'selfserve',
  LegacySS = 'legacy-ss',
  MLS = 'mls',
  Partner = 'partner',
  Investor = 'investor',
  Internal = 'internal',
}

export interface OrganizationapiCreateOrgRequestInt
  extends OrganizationapiCreateOrgRequest {
  type: OrgType;
}

export type UpdateOrgParams = Partial<OrganizationapiUpdateOrgRequestInt> & {
  orgId: number;
  type: OrgType;
};

export type OrganizationapiOrgResponse = (
  | OrganizationapiOrgResponseInt
  | (OrganizationapiOrgResponseExt & {
      lastSyncedWithM3ter: undefined;
      stripeCustomerId: undefined;
    })
) & { type: OrgType };

export type OrganizationapiInviteListResponseItem =
  | OrganizationapiInviteListResponseExt
  | OrganizationapiInviteListResponseInt;

export type OrganizationapiInviteListResponse =
  OrganizationapiInviteListResponseItem[];

export type OrganizationapiCreateOrgInviteRequest = Required<
  | OrganizationapiCreateOrgInviteRequestExt
  | OrganizationapiCreateOrgInviteRequestInt
>;

export type OrganizationapiInviteResponse = Required<
  OrganizationapiInviteResponseExt | OrganizationapiInviteResponseInt
>;

export type OrganizationapiResendOrgInviteRequest =
  | OrganizationapiResendOrgInviteRequestInt
  | OrganizationapiResendOrgInviteRequestExt;

export type OrganizationapiOrgUser = RequireCertainObjectKeys<
  OrganizationapiOrgUserResponseInt | OrganizationOrgUserResponseExt,
  'id' | 'email'
>;

export type OrganizationapiOrgUserListResponse = OrganizationapiOrgUser[];

export type OrganizationapiDeleteInviteReq =
  | OrganizationapiDeleteInviteReqInt
  | OrganizationapiDeleteInviteReqExt;

export type OrganizationapiUploadOrgInvitesResponse = Required<
  | OrganizationapiUploadOrgInvitesResponseExt
  | OrganizationapiUploadOrgInvitesResponseInt
>;

type BulkInviteTaskStatus = 'uploaded' | 'processing' | 'completed' | 'failed';

export type OrganizationapiBulkInviteUploadTaskStatusResponse = Omit<
  | OrganizationapiBulkInviteUploadTaskStatusResponseExt
  | OrganizationapiBulkInviteUploadTaskStatusResponseInt,
  'status'
> & {
  status: BulkInviteTaskStatus;
};
